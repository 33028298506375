import api from '../library/axios';

export default {
  getList({
    workspace_id,
    company_id,
    page,
    start_date,
    end_date,
    date_filter_type,
    status_filter,
  }) {
    return api({
      method: 'get',
      url: `/api/reports/${workspace_id}?company_id=${company_id}`,
      params: {
        page,
        start_date,
        end_date,
        date_filter_type,
        status_filter,
      },
    });
  },
  download({
    workspace_id,
    company_id,
    token,
    page,
    start_date,
    end_date,
    date_filter_type,
    status_filter,
    filter_export,
    delimiter,
  }) {
    return api({
      method: 'get',
      url: `/api/reports/${workspace_id}/download?company_id=${company_id}`,
      params: {
        page,
        start_date,
        end_date,
        date_filter_type,
        status_filter,
        filter_export,
        delimiter,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  downloadChatlog({
    workspace_id,
    company_id,
    token,
    page,
    start_date,
    end_date,
    date_filter_type,
    status_filter,
    filter_export,
    delimiter,
  }) {
    return api({
      method: 'get',
      url: `/api/reports/${workspace_id}/download/?company_id=${company_id}`,
      params: {
        page,
        start_date,
        end_date,
        date_filter_type,
        status_filter,
        filter_export,
        delimiter,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  batchList({
    workspace_id,
    company_id,
    page,
    start_date,
    end_date,
  }) {
    return api({
      method: 'get',
      url: `/api/broadcast/reports/${workspace_id}?company_id=${company_id}`,
      params: {
        page,
        start_date,
        end_date,
      },
    });
  },
  batchDetail({
    workspace_id,
    company_id,
    token,
    batch_id,
    start_date,
    end_date,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/broadcast/reports/${workspace_id}/${batch_id}?company_id=${company_id}`,
      params: {
        query,
        start_date,
        end_date,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  batchDetailDownload({
    workspace_id,
    company_id,
    token,
    batch_id,
    start_date,
    end_date,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/broadcast/reports/${workspace_id}/${batch_id}/download?company_id=${company_id}`,
      params: {
        query,
        start_date,
        end_date,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  downloadList({
    workspace_id,
    company_id,
    page,
  }) {
    return api({
      method: 'get',
      url: `/api/downloads/reports/${workspace_id}?company_id=${company_id}`,
      params: {
        page,
      },
    });
  },
  sessionList({
    workspace_id,
    channel_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/channel-sessions/${workspace_id}/${channel_id}`,
      params: query,
    });
  },
  downloadSessionList({
    workspace_id,
    company_id,
    token,
    channel_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/channel-sessions/${workspace_id}/${channel_id}/download?company_id=${company_id}`,
      params: query,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  chatlogList({
    workspace_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/chatlog/${workspace_id}`,
      params: query,
    });
  },
  downloadChatlogList({
    workspace_id,
    company_id,
    token,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/chatlog/${workspace_id}/download?company_id=${company_id}`,
      params: query,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
